body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.dzu-dropzone {
  /* min-height: 50px !important; */
  background: white;
}


#toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: 10%;
  bottom: 30px;
  font-size: 17px;
  font-family: 'Helvetica', sans-serif;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}


/* #root > div > div > div > div {
  background-color: var(--team-secondary) !important;
} */
/* .navbar{
  width: 100vw!important;
} */
/* .card {
  background-color: var(--team-primary);
} */