:root {
  /* --base: $primary; */
  /* --team-primary: #ffffff;
  --team-secondary: #ffffff; */
  --team-primary: #773141;
  --team-secondary: #ffb612;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  text-align: center;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar>.btn {
  margin-right: 1rem;
}

.react-bootstrap-table table {
  table-layout: auto;
}

/* Styles hacked in by Stuart  */
div.card-header>h2 {
  font-size: 1.1rem;
  color: var(--team-secondary);
  /* text-size-adjust: 50% !important; */
}

div.card-header>a {
  color: var(--team-secondary) !important;
  text-size-adjust: 50%;
}

div.card-header>h1 {
  font-size: 1.75rem;
  color: var(--team-secondary);
}

.card-header {
  background-color: var(--team-primary) !important;
}

.card-footer {
  background-color: var(--team-primary) !important;
  color: var(--team-secondary)
}

div.card-body {
  background-color: var(--team-primary) !important;
}

.card-group {
  background-color: var(--team-secondary) !important;
}

.card {
  border-color: var(--team-primary) !important;
  border-width: 2px !important;
}

div.card-body>button {
  background-color: var(--team-secondary) !important;
  border-color: var(--team-primary) !important;
  color: var(--team-primary) !important;
}

.input-group>.input-group-append>.btn {
  background-color: var(--team-secondary) !important;
  border-color: var(--team-primary) !important;
  color: var(--team-primary) !important;
}

.color-nav {
  background-color: rgb(95, 174, 31);
}

.leaflet-container {
  width: 100%;
  height: 70vh;
}

.img-profile {
  max-width: 50%;
  height: auto;
}

/* #root > div > div > div > div {
  background-color: var(--team-secondary) !important;
} */
/* .navbar{
  width: 100vw!important;
} */
/* .card {
  background-color: var(--team-primary);
} */